import clsx from 'clsx';
import styles from './StatsCard.module.scss';

interface IStatsCard {
    title: string;
    subtitle: {
        xs: string;
        sm: string;
        md: string;
    };
    classname?: string;
}

const StatsCard: React.FC<IStatsCard> = ({ title, subtitle, classname = '' }) => {
    return (
        <div className={clsx(styles.StatsCard, classname)}>
            <h2>{title}</h2>
            <div className={styles.text}>
                <p className={styles.xs}>{subtitle.xs}</p>
                <p className={styles.sm}>{subtitle.sm}</p>
                <p className={styles.md}>{subtitle.md}</p>
            </div>
        </div>
    );
};

export default StatsCard;
