import Button from 'epn-ui/umd/dist/components/Button';

// Styles
import styles from './RedButton.module.scss';

export interface IRedButtonProps {
    block?: boolean;
    size?: 'large' | 'small' | 'default' | 'medium';
    href: string;
    blank?: boolean;
}

const RedButton: React.FC<IRedButtonProps> = ({
    block = true,
    size = 'large',
    href,
    children,
    blank,
}) => {
    return (
        <Button.Beta
            danger
            block={block}
            size={size}
            className={styles.RedButton}
            href={href}
            target={blank ? '_blank' : ''}
        >
            {children}
        </Button.Beta>
    );
};

export default RedButton;
