import clsx from 'clsx';
import styles from './ContainerWithLayout.module.scss';

enum bgColorNames {
    red = 'red',
    grey = 'grey',
}
interface IProps {
    colorName: keyof typeof bgColorNames;
    children: React.ReactNode;
}

const ContainerWithLayout: React.FC<IProps> = ({ colorName, children }) => {
    return (
        <div className={styles.container}>
            <div
                className={clsx(styles.container_layout, {
                    [styles.container_layout__red]: colorName === bgColorNames.red,
                    [styles.container_layout__grey]: colorName === bgColorNames.grey,
                })}
            >
                <div className={styles.container_children}>{children}</div>
            </div>
        </div>
    );
};

export default ContainerWithLayout;
