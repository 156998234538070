import { useMemo, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import Grid from 'epn-ui/umd/dist/components/Grid';
import Empty from 'epn-ui/umd/dist/components/Empty';

// Icons
import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons/faShoppingCart';
import { faBook } from '@fortawesome/pro-regular-svg-icons/faBook';
import { faWallet } from '@fortawesome/pro-regular-svg-icons/faWallet';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';

// Config
import routes from '@config/routes.json';

// Helpers
import useI18n, { LocaleTypes } from '@i18n';
import apiHooks, { ICreateUseRequestInfiniteResponse } from '@apiHooks';
import { IGetOffersDataFormatted, IGetOffersCompilationsDataFormatted } from '@api/types';
import { ICompilation } from './BlockOurPartners.interface';

// Components
import OurPartnerCard from './OurPartnerCard';
import SectionTitle from '../Base/SectionTitle';
import PartnersTabs from './PartnersTabs';
import RedButton from './RedButton';

import styles from './BlockOurPartners.module.scss';

const { useOffers, useOffersCompilations } = apiHooks;

const SIMPLE_ARRAY_FOR_PRELOADER = [1, 2, 3, 4, 5, 6];

const DEFAULT_COMPILATIONS: ICompilation[] = [
    {
        icon: faShoppingCart,
        name: 'e-Commerce',
        id: 44,
        offersIds: [],
        offersCount: 6,
        priority: 1,
    },
    { icon: faWallet, name: 'Финансы', id: 26, offersIds: [], offersCount: 30, priority: 2 },
    { icon: faBook, name: 'Образование', id: 34, offersIds: [], offersCount: 6, priority: 3 },
    { icon: faEllipsisV, name: 'Другое', id: 99, offersIds: [], offersCount: 6, priority: 4 },
];

const getPreparedCompilations = (data: IGetOffersCompilationsDataFormatted[]) => {
    const compilationsWithIds = data.reduce((acc, current) => {
        const finded = DEFAULT_COMPILATIONS.find(tab => tab.id === current.id);
        if (finded) {
            return [...acc, { ...finded, offersIds: current.offersIds }];
        }
        return acc;
    }, []);
    const compilationsSorted = compilationsWithIds.sort((a, b) => a.priority - b.priority);
    return compilationsSorted as ICompilation[];
};

const BlockOurPartners: React.FC = () => {
    const [compilation, setCompilation] = useState<ICompilation>();

    const { t, locale } = useI18n();

    const isEnglishLanding = locale === LocaleTypes.en;

    const {
        data: offersCompilationsData = [],
        isLoading: isLoadingCategories,
    } = useOffersCompilations({
        params: {
            viewRules: 'role_user',
            status: 'active',
            limit: 30,
            offset: 0,
        },
        optionalAuth: true,
        dependencies: [locale],
        condition: !isEnglishLanding,
    }) as ICreateUseRequestInfiniteResponse<IGetOffersCompilationsDataFormatted[]>;

    const {
        data: offersCompilationsaArchivedData = [],
        isLoading: isLoadingCategoriesArchived,
    } = useOffersCompilations({
        params: {
            viewRules: 'role_user',
            status: 'archived',
            limit: 30,
            offset: 0,
        },
        optionalAuth: true,
        dependencies: [locale],
        condition: !isEnglishLanding,
    }) as ICreateUseRequestInfiniteResponse<IGetOffersCompilationsDataFormatted[]>;

    const compilations = useMemo<ICompilation[]>(() => {
        if (
            isLoadingCategories ||
            isLoadingCategoriesArchived ||
            isEnglishLanding ||
            !offersCompilationsData.length ||
            !offersCompilationsaArchivedData.length
        )
            return [];
        const preparedCompilations = getPreparedCompilations([
            ...offersCompilationsData,
            ...offersCompilationsaArchivedData,
        ]);
        // Выбираю первую подборку по дефолту
        if (!compilation) {
            setCompilation(preparedCompilations[0]);
        }
        return preparedCompilations as ICompilation[];
    }, [
        compilation,
        isEnglishLanding,
        isLoadingCategories,
        isLoadingCategoriesArchived,
        offersCompilationsData,
        offersCompilationsaArchivedData,
    ]);

    const { data: offers, isLoading: offersIsLoading } = useOffers({
        params: {
            limit: compilation?.offersCount || 6,
            lang: locale,
            viewRules: 'role_user',
            order: 'priority',
            typeId: '1',
            ids: !isEnglishLanding ? compilation?.offersIds || '' : '',
            fields:
                'name,logo,id,primaryColor,url,webmasterMaxRate,webmasterRateSymbol,image,link_default',
        },
        optionalAuth: true,
        dependencies: [locale, compilation],
        condition:
            (compilation?.offersIds.length > 0 && !!compilation.offersCount) || isEnglishLanding,
    }) as ICreateUseRequestInfiniteResponse<IGetOffersDataFormatted[]>;

    const preparedOffers = useMemo(() => {
        // Лютый костыль для отображения уникальных офферов
        if (compilation?.id === 26) {
            // Для каждого домена создаю свой объект
            try {
                const uniqleOffers = new Map();
                offers.forEach(offer => {
                    const domain = new URL(offer.linkDefault).hostname;
                    const uniqleOffersByDomain = uniqleOffers.get(domain);
                    // Кладу уникальный оффер, если его ещё нет
                    if (!uniqleOffersByDomain) {
                        uniqleOffers.set(domain, offer);
                        return;
                    }
                    // Кладу оффер с большим рейтом, чем прошлый
                    if (
                        uniqleOffersByDomain &&
                        offer.webmasterMaxRate > uniqleOffersByDomain.webmasterMaxRate
                    ) {
                        uniqleOffers.set(domain, offer);
                    }
                });
                const arrayOfUniqleOffers = Array.from(uniqleOffers, item => ({ ...item[1] }));
                if (arrayOfUniqleOffers.length > 0) {
                    return arrayOfUniqleOffers.slice(0, 6);
                }
                return offers;
            } catch (err) {
                console.error(err);
                Sentry.captureException(err);
                return offers;
            }
        }
        return offers;
    }, [compilation?.id, offers]);

    const handleTabs = (id: number) => {
        const current = compilations.find(currentCompilation => currentCompilation.id === id);
        setCompilation(current);
    };

    const offersIsEmpty = !Array.isArray(offers) || preparedOffers.length === 0;

    const offersListIsLoading =
        offersIsLoading || isLoadingCategoriesArchived || isLoadingCategories;

    return (
        <div className={styles.BlockOurPartners}>
            <div className={styles.landingContainer}>
                <SectionTitle classname={styles.BlockOurPartners_title}>
                    {t('Our partners')}
                </SectionTitle>
                {!isEnglishLanding && (
                    <PartnersTabs
                        list={compilations}
                        currentCompilation={compilation?.id}
                        onChangeTab={handleTabs}
                        isLoading={!!offersListIsLoading}
                    />
                )}
                <div className="mb-8 mt-[36px] xs:mt-[32px] sm:mt-[40px] md:mt-[48px] lg:mt-[64px] xl:mt-[80px] text-promo2">
                    {offersIsEmpty && !offersListIsLoading && (
                        <Empty
                            description={t(
                                'Unfortunately, we were unable to load the list of affiliate programs. Please contact technical support.',
                            )}
                        />
                    )}

                    {(!Array.isArray(preparedOffers) || preparedOffers.length === 0) &&
                        offersListIsLoading && (
                            <Grid gutter={[32, 32]} wrap>
                                {SIMPLE_ARRAY_FOR_PRELOADER.map(item => (
                                    <Grid.Col
                                        xs={{ span: 12 }}
                                        md={{ span: 8 }}
                                        lg={{ span: 8 }}
                                        key={item}
                                    >
                                        <OurPartnerCard loading={offersListIsLoading} />
                                    </Grid.Col>
                                ))}
                            </Grid>
                        )}

                    {Array.isArray(preparedOffers) &&
                        preparedOffers.length !== 0 &&
                        !offersListIsLoading && (
                            <div className={styles.BlockOurPartners_list}>
                                {preparedOffers.map(item => (
                                    <OurPartnerCard offer={item} key={item.id} />
                                ))}
                            </div>
                        )}
                    <div className={styles.BlockOurPartners__actions}>
                        <RedButton href={`/${locale}${routes.offers}`} blank>
                            {t('All partners')}
                        </RedButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlockOurPartners;
