import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import Button from 'epn-ui/umd/dist/components/Button';
import Skeleton from 'epn-ui/umd/dist/components/Skeleton';
// Config
import routes from '@config/routes.json';

// Helpers
import useI18n from '@i18n';
import { IGetOffersDataFormatted } from '@api/types';

export interface IOurPartnerCardProps {
    offer?: IGetOffersDataFormatted;
    loading?: boolean;
}

const OurPartnerCard: React.FC<IOurPartnerCardProps> = ({ offer, loading }) => {
    const { t, locale } = useI18n();

    if (loading) {
        return (
            <Skeleton
                loading
                shape="round"
                title={{
                    style: { width: '320', height: '228px', borderRadius: '10px' },
                }}
            />
        );
    }

    return (
        <div className="overflow-hidden bg-white shadow-2 rounded-4 w-[304px] h-[221px] w-max-full xs:w-[272px] xs:h-[221px] sm:w-[321px] md:w-[289px] md:h-[235px] lg:w-[348px] xl:w-[400px] hover:shadow-1 transition-shadow">
            <div className="flex justify-center w-full pt-12 pb-4">
                <img src={offer?.image} alt={offer?.name} className="max-w-48 min-h-16" />
            </div>
            <div className="flex items-center justify-between p-6">
                <div>
                    <div className="font-medium text-p1 text-brandBlue-300">{t('Reward')}</div>
                    <div className="font-semibold text-h2 text-brandBlue-500">
                        {t('up to')} {offer?.webmasterMaxRate || ''}
                        {offer?.webmasterMaxRateSymbol || '%'}
                    </div>
                </div>
                <div>
                    <Button.Beta
                        shape="circle"
                        danger
                        className="border-2 border-[#FE0000] text-[#FE0000]"
                        icon={faArrowRight}
                        target="_blank"
                        href={`/${locale}${routes.offer.replace(':id', offer?.url)}`}
                    />
                </div>
            </div>
        </div>
    );
};

export default OurPartnerCard;
