import clsx from 'clsx';
import useI18n from '@i18n/index';
import StatsCard from './StatsCard';

import styles from './Stats.module.scss';

const Stats: React.FC = () => {
    const { t } = useI18n();
    return (
        <section className={clsx(styles.landingContainer, styles.stats)}>
            <StatsCard
                title="770 000+"
                subtitle={{
                    xs: t('Webmasters work with us'),
                    sm: t('all webmasters'),
                    md: t('Webmasters all over the world work with us'),
                }}
                classname={styles.stats_block}
            />
            <StatsCard
                title="$74.4M+"
                subtitle={{
                    xs: t('Paid out to our partners'),
                    sm: t('Paid to our'),
                    md: t('Paid out to our partners'),
                }}
                classname={styles.stats_block}
            />
            <StatsCard
                title="$1.5B+"
                subtitle={{
                    xs: t('Generated in revenue for advertisers'),
                    sm: t('Generated in revenue'),
                    md: t('Generated in revenue for advertisers'),
                }}
                classname={styles.stats_block}
            />
        </section>
    );
};

export default Stats;
