import clsx from 'clsx';
import Skeleton from 'epn-ui/umd/dist/components/Skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IPartnersTabsProps } from '../BlockOurPartners.interface';

import styles from './PartnerTabs.module.scss';

const PartnersTabs: React.FC<IPartnersTabsProps> = ({
    list,
    currentCompilation,
    onChangeTab,
    isLoading,
}) => {
    return (
        <ul className={styles.tabs}>
            {isLoading && (
                <Skeleton
                    loading
                    shape="square"
                    title={{
                        style: { width: '100%', height: '48px' },
                    }}
                />
            )}
            {!isLoading &&
                list.map(btn => (
                    <li
                        className={clsx(styles.tab, {
                            [styles.tab__active]: currentCompilation === btn.id,
                        })}
                        key={btn.id}
                        onClick={() => onChangeTab(btn.id)}
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="button"
                        aria-hidden
                    >
                        <FontAwesomeIcon className={styles.tabIcon} icon={btn.icon} />
                        {btn.name}
                    </li>
                ))}
        </ul>
    );
};

export default PartnersTabs;
