import { useState } from 'react';

import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import useI18n from '@i18n';

interface IBaseCollapseWithGradient {
    children: React.ReactNode;
    maxHeight?: string;
    classname?: string;
}

const BaseCollapseWithGradient: React.FC<IBaseCollapseWithGradient> = ({
    children,
    maxHeight = '270px',
}) => {
    const { t } = useI18n();

    const [isLoadedMore, setIsLoadedMore] = useState<boolean>(false);
    const loadMoreStyles = `overflow-hidden before:content-[""] before:absolute before:w-full before:h-full before:bg-gradientWhite`;
    return (
        <>
            <div
                className={clsx('relative', { [loadMoreStyles]: !isLoadedMore })}
                style={{ maxHeight: isLoadedMore ? '' : maxHeight }}
            >
                {children}
            </div>
            <div className="relative flex items-center justify-center w-full mt-[20px]">
                {!isLoadedMore ? (
                    <button
                        type="button"
                        className="absolute bottom-[20px]"
                        onClick={() => setIsLoadedMore(true)}
                    >
                        <span className="mr-[5px]">{t('Show all')}</span>
                        <FontAwesomeIcon icon={faAngleDown} />
                    </button>
                ) : (
                    <button type="button" onClick={() => setIsLoadedMore(false)}>
                        <span className="mr-[5px]">{t('Hide')}</span>
                        <FontAwesomeIcon icon={faAngleUp} />
                    </button>
                )}
            </div>
        </>
    );
};

export default BaseCollapseWithGradient;
