import BaseSanitizer from '@components/base/BaseSanitizer';

import styles from './NetworkCard.module.scss';

interface INetworkCardProps {
    imgSrc: string;
    title: string;
    text: string;
}

const NetworkCard: React.FC<INetworkCardProps> = ({ imgSrc, title, text }) => {
    return (
        <div className={styles.networkBlock}>
            <div className={styles.networkBlock_img}>
                <img src={imgSrc} alt={title} />
            </div>
            <div className={styles.networkBlock_text}>
                <BaseSanitizer html={title} tag="h3" />
                <BaseSanitizer html={text} tag="p" />
            </div>
        </div>
    );
};

export default NetworkCard;
