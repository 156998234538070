import clsx from 'clsx';
import BaseSanitizer from '@components/base/BaseSanitizer';

import styles from './SectionTitle.module.scss';

interface ISectionTitle {
    children: string;
    classname?: string;
    bigger?: boolean;
}

const SectionTitle: React.FC<ISectionTitle> = ({ children, classname, bigger }) => {
    return (
        <BaseSanitizer
            html={children}
            className={clsx(styles.title, classname, { [styles.title__bigger]: bigger })}
            tag="h2"
        />
    );
};

export default SectionTitle;
